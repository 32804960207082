import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Spinner , Form, Row, Col, Button} from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';

import {Helmet} from "react-helmet";

const Password = () => {
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.DASHBOARD_ACCOUNT_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password")
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                }else{
                    navigate('/login');
                }
            },
            error: function(e){
                navigate('/login');
            }
        });
    }

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        sectionContainerStyle: {
            display: "flex",
            paddingTop: "30px",
            paddingBottom: "30px",
        },

        sectionStyle: {
            flex: "1"
        },

        sectionTitleStyle: {
            marginLeft: "15px"
        },

        sectionSubtitleStyle: {
            marginLeft: "15px",
            marginRight: "80px",
            marginBottom: "10px"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const [inputPassword, setPassword] = useState("");
    const [inputPasswordConfirmation, setPasswordConfirmation] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        setErrorMessage("");
        if(target === "input_password"){
            setPassword(value);
            if(value.length > 0){
                if(value.length >= 8){
                    setErrorMessage("");
                }else{
                    setErrorMessage("Password harus terdiri dari paling tidak 8 karakter");
                }
            }else{
                setErrorMessage("");
            }
        }else if(target === "input_password_confirm"){
            if(value === inputPassword){
                setErrorMessage("");
            }else{
                setErrorMessage("Password tidak sama");
            }
            setPasswordConfirmation(value);
        }
    }

    const submit = () => {
        setIsLoading(true);
        $.ajax({
            type: "POST",
            url: UrlHouse.DASHBOARD_CHANGE_PASSWORD,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), new_password: inputPassword
            }),
            dataType: "json",
            success: function (response) {
                setIsLoading(false);
                if(response.result === 1){
                    localStorage.setItem("password", inputPassword);
                    alert("Password telah diubah")
                    navigate('/dashboard/account')
                }else{
                    alert("Ups, terjadi kesalahan. Mohon coba lagi nanti.")
                }
            },
            error: function(e){
                setIsLoading(false);
                alert("Ups, terjadi kesalahan. Mohon coba lagi nanti.")
            }
        });
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    <div style={styles.mainContainerStyle}>

                        <h1>Ubah Password</h1>
                        <hr></hr>
                        <Form>
                            <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control onChange={onFormChange} name="input_password" type="password" values={inputPassword}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Konfirmasi Password</Form.Label>
                                    <Form.Control onChange={onFormChange} name="input_password_confirm" type="password" values={inputPassword}/>
                                </Form.Group>
                                {errorMessage.length > 0?<label style={{marginBottom: "10px", color: "red"}}>{errorMessage}</label>:<></>}
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 12}} style={{display: "flex"}}>
                                        <Button onClick={submit} disabled={errorMessage.length > 0 || !isLoaded || isLoading} style={styles.sendButtonStyle}>Ubah Password</Button>
                                        {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                    </Col>
                                </Form.Group>
                        </Form>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default Password;