import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import { UserData } from "../../utils/UserData";
import Logo from '../../asset/logo_long_transparent_cropped.png';

import "../../spinner.css";

import { useNavigate } from "react-router-dom";

import { Row, Col, Form, Button, Card } from 'react-bootstrap';

import DecorationImage from '../../asset/login_decoration.jpg';

import {Helmet} from "react-helmet";

const ConnectWoiloPage = () => {
    const appContext = useContext(AppContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            display: "flex",
            padding: "30px",
        },
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      //$('body').css('background-color','#2E80EB');
      init();
    }, [])

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    const init = () => {
        setIsLoaded(false);
        $.ajax({
            type: "POST",
            url: UrlHouse.CONNECT_WOILO_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password")
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                }else{
                    navigate('/login');
                }
            },
            error: function(e){
                navigate('/login');
            }
        });
    }

    const params = useParams();
    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const [inputUserName, setUserName] = useState("");
    const [inputPassword, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(target === "input_user_name"){
            setUserName(value);
        }else if(target === "input_password"){
            setPassword(value);
        }
        setErrorMessage("");
    }

    const submit = () => {
        setIsLoading(true);
        $.ajax({
            type: "POST",
            url: UrlHouse.CONNECT_WOILO,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), woilo_user_name: inputUserName, woilo_password: inputPassword
            }),
            dataType: "json",
            success: function (response) {
                setIsLoading(false);
                if(response.result === 1){
                    if(response.result_2 === 1){
                        navigate('/dashboard');
                    }else if(response.result_2 === 2){
                        setErrorMessage("Username atau password yang kamu masukkan salah. Pastikan kamu memasukkan username dan password akun Woilo kamu.");
                    }else{
                        setErrorMessage("Akun Woilo tidak ditemukan");
                    }
                }else{
                    alert("Ups, terjadi kesalahan. Coba lagi nanti.");
                }
            },
            error: function(e){
                setIsLoading(false);
                alert("Ups, terjadi kesalahan. Coba lagi nanti.");
            }
        });
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?
                    <div style={styles.mainContainerStyle}>
                        <div style={styles.loginContainerStyle}>
                            <h1>Hubungkan Akun Woilo</h1>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Username Woilo</Form.Label>
                                    <Form.Control onChange={onFormChange} name="input_user_name" type="text" values={inputUserName}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password Woilo</Form.Label>
                                    <Form.Control onChange={onFormChange} name="input_password" type="password" values={inputPassword}/>
                                </Form.Group>
                                {errorMessage.length > 0?<label style={{marginBottom: "5px", color: "red"}}>{errorMessage}</label>:<></>}
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 12}} style={{display: "flex"}}>
                                        <Button onClick={submit} style={styles.sendButtonStyle} disabled={isLoading}>Hubungkan Akun</Button>
                                        {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Link onClick={onDownload} style={{textDecoration: "none"}}>Belum punya akun Woilo? Daftar sekarang</Link>
                        </div>
                    </div>
                    :
                    <></>
                    
                }
            </div>
        </>
    )
}

export default ConnectWoiloPage;