import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import "../../spinner.css";

import {Helmet} from "react-helmet";
import axios from 'axios';
import { Label } from "recharts";

const FinishMessagePage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [adType, setAdType] = useState(101);
    const [adName, setAdName] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingMessage, setUploadingMessage] = useState("");

    const targetMin = 500;
    const targetMax = 10000;

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.FINISH_MESSAGE_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), message_id: params.id
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                    setAdType(response.message_type);
                    setAdName(response.message_name);
                }else if(response.result === 2){
                    navigate('/dashboard/message_ads/create/confirm/'+params.id);
                }else{
                    navigate('/dashboard/message_ads/create/setup/'+params.id);
                }
            },
            error: function(e){
                navigate('/dashboard/message_ads');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

	const onSubmitButtonClick = async() => {
        setIsUploading(true);
        setUploadingMessage("Menyelesaikan Pesan");
        $.ajax({
            type: "POST",
            url: UrlHouse.FINISH_MESSAGE,
            data: JSON.stringify({
                email: localStorage.getItem("email"),
                password: localStorage.getItem("password"),
                message_id: params.id,
                start_date: startDate,
                target: target
            }),
            dataType: "json",
            success: function (response) {
                setIsUploading(false);
                if(response.result === 1){
                    navigate('/dashboard/message_ads/create/confirm/'+params.id);
                }else{
                    alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
                }
            },
            error: function(e){
                setIsUploading(false);
                alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
            }
        });
	};

    const [startDate, setStartDate] = useState("");
    const [startDateMessage, setStartDateMessage] = useState("");
    const [target, setTarget] = useState(0);
    const [targetMessage, setTargetMessage] = useState("");
    const [targetError, setTargetError] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(value.length > 0){
            if(target === "input_start_date"){
                setStartDateMessage("Iklan ini akan dimulai pada tanggal " + value);
                let date = value.replace(/-/gi, "/");
                setStartDate(date);
            }
            if(target === "input_target"){
                let target = parseInt(value);
                if(target < targetMin){
                    setTargetError("Target pengguna tidak boleh kurang dari 500 pengguna")
                    setTarget(0)
                    setTargetMessage("")
                }else if(target > targetMax){
                    setTargetError("Target pengguna tidak boleh lebih dari 10.000 pengguna")
                    setTarget(0)
                    setTargetMessage("")
                }else{
                    setTargetError("")
                    setTarget(target)
                    

                    const formatter = new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                    });
                    setTargetMessage("Biaya total dari iklan ini tidak akan lebih dari " + formatter.format(target*100))
                }
            }
        }else{
            if(target === "input_start_date"){
                setStartDateMessage("");
                setStartDate("");
            }
            if(target === "input_budget"){
                setTarget(0);
            }
        }
    }

    function getAdType(){
        if(adType === 101){
            return "Iklan Foto";
        }
        if(adType === 102){
            return "Iklan Video";
        }
        if(adType === 103){
            return "Iklan Karosel";
        }
        return "Iklan Foto";
    }

    const onBack = () => {
        navigate('/dashboard/message_ads')
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?<div style={styles.mainContainerStyle}>
                        <h1>Atur Pengiriman Pesan</h1>
                        <h4>{adName}  •  {getAdType()}</h4>
                        <hr></hr>
                        <div>
                            <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Tanggal Pengiriman Pesan</Form.Label>
                                {startDateMessage.length > 0?<div style={{marginBottom: "10px", color: appContext.colors.primaryGrey}}>{startDateMessage}</div>:<></>}
                                <Form.Control onChange={onFormChange} name="input_start_date" type="date" min='2022-11-04'/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Jumlah Pengguna Yang Menerima Pesan</Form.Label>
                                <p style={{color: appContext.colors.primaryGrey}}>WoiloAds akan mengirimkan ke jumlah pengguna yang kamu masukkan dengan biaya Rp.100 per pengguna.</p>
                                {targetMessage.length > 0 && targetError.length === 0?<div style={{marginBottom: "10px", color: appContext.colors.primaryGrey}}>{targetMessage}</div>:<></>}
                                {targetError.length > 0?<div style={{marginBottom: "10px", color: "red"}}>{targetError}</div>:<></>}
                                <Form.Control onChange={onFormChange} name="input_target" type="number" step="1" min="500" max="10000"/>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}} style={{display: "flex"}}>
                                    <Button onClick={onSubmitButtonClick} disabled={startDate.length === 0 || target === 0 || isUploading} style={styles.sendButtonStyle}>Selesai</Button>
                                    {isUploading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                    {isUploading?<div style={{marginTop: "5px", marginLeft: "10px"}}>{uploadingMessage}</div>:<></>}
                                    <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue, marginLeft: "10px"}}>Kembali</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        </div>
                    </div>:<></>
                    
                }
            </div>
        </>
    )
}

export default FinishMessagePage;