export default class UrlHouse {

    //static BASE_URL = "https://d5y4.ok3t.lyr.id/apis/v2/";
    //static BASE_URL = "https://ads.sestyc.com/apis/v2/";
    static BASE_URL = "https://ads.woilo.com/apis/v2/";

    static LOGIN = this.BASE_URL + "login.php";
    static REGISTER = this.BASE_URL + "register.php";
    static RESET_PASSWORD = this.BASE_URL + "reset_password.php";
    static VERIFY_EMAIL_INIT = this.BASE_URL + "verify_email_init.php";
    static VERIFY_EMAIL_CHECK = this.BASE_URL + "verify_email_check.php";
    static VERIFY_EMAIL = this.BASE_URL + "verify_email.php";

    static CONNECT_WOILO_INIT = this.BASE_URL + "connect_woilo_init.php";
    static CONNECT_WOILO = this.BASE_URL + "connect_woilo.php";

    static CREATE_AD = this.BASE_URL + "create/create_ad.php";
    static SETUP_AD_INIT = this.BASE_URL + "create/setup_ad_init.php";
    static FINISH_AD_INIT = this.BASE_URL + "create/finish_ad_init.php";
    static SETUP_IMAGE_AD = this.BASE_URL + "create/setup_image_ad.php";
    static SETUP_VIDEO_AD = this.BASE_URL + "create/setup_video_ad.php";
    static SETUP_CAROUSEL_AD = this.BASE_URL + "create/setup_carousel_ad.php";
    static FINISH_AD = this.BASE_URL + "create/finish_ad.php";
    static CONFIRM_AD_INIT = this.BASE_URL + "create/confirm_ad_init.php";
    static INVOICE_AD_INIT = this.BASE_URL + "create/invoice_ad_init.php";
    static AD_PAYMENT_INIT = this.BASE_URL + "create/ad_payment_init.php";
    static UPLOAD_IMAGE = this.BASE_URL + "create/upload_image.php";
    static UPLOAD_VIDEO = this.BASE_URL + "create/upload_video.php";
    static PAYMENT_BCA = this.BASE_URL + "create/payment_bca.php";

    static CREATE_MESSAGE = this.BASE_URL + "create_message/create_message.php";
    static SETUP_MESSAGE_INIT = this.BASE_URL + "create_message/setup_message_init.php";
    static SETUP_MESSAGE = this.BASE_URL + "create_message/setup_message.php";
    static SETUP_MESSAGE_IMAGE = this.BASE_URL + "create_message/setup_message_image.php";
    static SETUP_MESSAGE_VIDEO = this.BASE_URL + "create_message/setup_message_video.php";
    static UPLOAD_IMAGE_MESSAGE = this.BASE_URL + "create_message/upload_image_message.php";
    static UPLOAD_VIDEO_MESSAGE = this.BASE_URL + "create_message/upload_video_message.php";
    static FINISH_MESSAGE_INIT = this.BASE_URL + "create_message/finish_message_init.php";
    static FINISH_MESSAGE = this.BASE_URL + "create_message/finish_message.php";
    static CONFIRM_MESSAGE_INIT = this.BASE_URL + "create_message/confirm_message_init.php";
    static INVOICE_MESSAGE_INIT = this.BASE_URL + "create_message/invoice_message_init.php";
    static MESSAGE_PAYMENT_INIT = this.BASE_URL + "create_message/message_payment_init.php";
    static PAYMENT_BCA_MESSAGE = this.BASE_URL + "create_message/payment_bca_message.php";

    static TOPUP_INIT = this.BASE_URL + "topup/topup_init.php";
    static TOPUP = this.BASE_URL + "topup/topup.php";
    static TOPUP_PAYMENT_INIT = this.BASE_URL + "topup/payment_init.php";
    static UPLOAD_IMAGE_TOPUP = this.BASE_URL + "topup/upload_image_topup.php";
    static PAYMENT_BCA_TOPUP = this.BASE_URL + "topup/payment_bca_topup.php";

    static DASHBOARD_ADS_INIT = this.BASE_URL + "dashboard/ads_init.php";
    static DASHBOARD_AD_DETAIL_INIT = this.BASE_URL + "dashboard/ad_detail_init.php";
    static DASHBOARD_STOP_AD = this.BASE_URL + "dashboard/stop_ad.php";
    static DASHBOARD_DELETE_AD = this.BASE_URL + "dashboard/delete_ad.php";

    static DASHBOARD_BALANCE = this.BASE_URL + "dashboard/balance_init.php";
    static DASHBOARD_ACCOUNT_INIT = this.BASE_URL + "dashboard/account_init.php";
    static DASHBOARD_CHANGE_PASSWORD = this.BASE_URL + "dashboard/change_password.php";

    static DASHBOARD_MESSAGE_ADS_INIT = this.BASE_URL + "dashboard/message_ads_init.php";
    static DASHBOARD_MESSAGE_AD_DETAIL_INIT = this.BASE_URL + "dashboard/message_ad_detail_init.php";
    static DASHBOARD_STOP_MESSAGE = this.BASE_URL + "dashboard/stop_message.php";
    static DASHBOARD_DELETE_MESSAGE = this.BASE_URL + "dashboard/delete_message.php";

    
}