import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import { UserData } from "../../utils/UserData";
import Logo from '../../asset/logo_long_transparent_cropped_white.png';
import LogoBlue from '../../asset/logo_long_transparent_cropped.png';

import { useNavigate } from "react-router-dom";

import { Row, Col, Form, Button, Card } from 'react-bootstrap';

import DecorationImage from '../../asset/login_decoration.jpg';

import {Helmet} from "react-helmet";

const LoginPage = () => {
    const appContext = useContext(AppContext);
    const userData = useContext(UserData);
    const navigate = useNavigate();

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            display: "flex"
        },

        decorationStyle: {
            width: "50%",
            height: "100vh",
            backgroundImage: "url("+DecorationImage+")",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
            boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.8)",
            color: appContext.colors.primaryWhite,
            padding: window.innerWidth/20+"px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
        },

        decorationTitleStyle: {
            marginLeft: window.innerWidth/20+"px"
        },

        decorationDescriptionStyle: {
            marginLeft: window.innerWidth/20+"px",
            fontWeight: "300"
        },

        loginContainerStyle: {
            width: "50%",
            height: "100vh",
            padding: window.innerWidth/20+"px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
        },

        inputLabelStyle: {
            color: appContext.colors.primaryBlack
        },

        inputStyle: {
            width:  window.innerWidth/4+"px",
            border: "2px solid",
            borderColor: appContext.colors.primaryBlue,
            borderRadius: "4px",
            color: appContext.colors.primaryBlack,
            padding: "10px",
            marginTop: "5px",
            marginBottom: "5px"
        },

        submitButtonStyle: {
            width:  window.innerWidth/4+"px",
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
        },

        decorationStyle: {
            backgroundImage: "url("+DecorationImage+")",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
            boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.8)",
            color: appContext.colors.primaryWhite,
            padding: window.innerWidth/20+"px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
        },

        decorationTitleStyle: {
            marginLeft: window.innerWidth/20+"px"
        },

        decorationDescriptionStyle: {
            marginLeft: window.innerWidth/20+"px",
            fontWeight: "300"
        },

        loginContainerStyle: {
            padding: window.innerWidth/20+"px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
        },

        inputLabelStyle: {
            color: appContext.colors.primaryBlack
        },

        inputStyle: {
            width:  window.innerWidth/4+"px",
            border: "2px solid",
            borderColor: appContext.colors.primaryBlue,
            borderRadius: "4px",
            color: appContext.colors.primaryBlack,
            padding: "10px",
            marginTop: "5px",
            marginBottom: "5px"
        },

        submitButtonStyle: {
            width:  window.innerWidth/4+"px",
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      //$('body').css('background-color','#2E80EB');
      
    }, [])

    const params = useParams();
    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    const [inputEmail, setEmail] = useState(userData.userEmail);
    const [inputPassword, setPassword] = useState(userData.userPassword);
    const [errorMessage, setErrorMessage] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(target === "input_email"){
            setEmail(value);
        }else if(target === "input_password"){
            setPassword(value);
        }
        setErrorMessage("");
    }

    const login = (event) => {
        // Prevent page reload
        $.ajax({
            type: "POST",
            url: UrlHouse.LOGIN,
            data: JSON.stringify({
                email: inputEmail, password: inputPassword
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    localStorage.setItem("email", inputEmail);
                    localStorage.setItem("password", inputPassword);
                    navigate('/dashboard');
                }if(response.result === 2){
                    localStorage.setItem("email", inputEmail);
                    localStorage.setItem("password", inputPassword);
                    navigate('/verify');
                }else{
                    setErrorMessage("Email atau password salah")
                }
            },
            error: function(e){
                setErrorMessage("Email atau password salah")
            }
        });
    };

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    appContext.mobileMode?<div style={styles.mainContainerStyle}>
                        <div style={styles.decorationStyle}>
                            <img src={LogoBlue} style={styles.decorationTitleStyle} width="200px" alt="logo"/>
                            <h2 style={styles.decorationTitleStyle}>
                                Jangkau Potensi Bisnismu lewat WoiloAds
                            </h2>
                            <p style={styles.decorationDescriptionStyle}>
                                Bangun bisnismu bersama WoiloAds, akses potensi pelanggan kamu dari seluruh Indonesia.
                            </p>
                        </div>
                        <div style={styles.loginContainerStyle}>
                            <h2>Masuk ke WoiloAds</h2>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control onChange={onFormChange} name="input_email" type="email" values={inputEmail}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control onChange={onFormChange} name="input_password" type="password" values={inputPassword}/>
                                </Form.Group>
                                {errorMessage.length > 0?<label style={{marginBottom: "5px", color: "red"}}>{errorMessage}</label>:<></>}
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 12}}>
                                        <Button onClick={login} style={styles.sendButtonStyle}>Masuk</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Link style={{textDecoration: "none"}} to="/register">Belum punya akun iklan? Daftar sekarang</Link>
                            <br></br>
                            <Link style={{textDecoration: "none"}} to="/recover">Lupa Password?</Link>
                            <p style={{marginTop: "50px", color: appContext.colors.primaryGrey, textAlign: "center"}}>
                                <i className="fa fa-copyright" aria-hidden="true"></i> 2023 PT Karya Digital Indo
                            </p>
                            <Link style={{textDecoration: "none", textAlign: "center", color: appContext.colors.primaryGrey}} to="../tos">
                                <p style={{marginTop: "5px"}}>
                                    Kebijakan Penggunaan & Kebijakan Layanan
                                </p>
                            </Link>
                        </div>
                    </div>
                    :<div style={styles.mainContainerStyle}>
                    <div style={styles.decorationStyle}>
                        <img src={Logo} style={styles.decorationTitleStyle} width="200px" alt="logo"/>
                        <h1 style={styles.decorationTitleStyle}>
                            Jangkau Potensi Bisnismu lewat WoiloAds
                        </h1>
                        <h5 style={styles.decorationDescriptionStyle}>
                            Bangun bisnismu bersama WoiloAds, akses potensi pelanggan kamu dari seluruh Indonesia.
                        </h5>
                    </div>
                    <div style={styles.loginContainerStyle}>
                        <img src={LogoBlue}width="200px" alt="logo"/>
                        <h1>Masuk ke WoiloAds</h1>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control onChange={onFormChange} name="input_email" type="email" values={inputEmail}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control onChange={onFormChange} name="input_password" type="password" values={inputPassword}/>
                            </Form.Group>
                            {errorMessage.length > 0?<label style={{marginBottom: "5px", color: "red"}}>{errorMessage}</label>:<></>}
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}}>
                                    <Button onClick={login} style={styles.sendButtonStyle}>Masuk</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        <Link style={{textDecoration: "none"}} to="/register">Belum punya akun iklan? Daftar sekarang</Link>
                        <br></br>
                        <Link style={{textDecoration: "none"}} to="/recover">Lupa Password?</Link>
                        <p style={{marginTop: "50px", color: appContext.colors.primaryGrey, textAlign: "center"}}>
                            <i className="fa fa-copyright" aria-hidden="true"></i> 2023 PT Karya Digital Indo
                        </p>
                        <Link style={{textDecoration: "none", textAlign: "center", color: appContext.colors.primaryBlue}} to="../tos">
                            <p style={{marginTop: "5px"}}>
                                Kebijakan Penggunaan & Kebijakan Layanan
                            </p>
                        </Link>
                    </div>
                </div>
                    
                }
            </div>
        </>
    )
}

export default LoginPage;